<template>
  <div>
    <common-header></common-header>
    <ul class="address-list warp">
      <li class="item" v-for="(item, index) in Lists" :key="index">
        <div @click="changeAddress(index)">
          <div class="top" >
            <span>{{item.receiveName}}</span>
            <span>{{item.receivePhone}}</span>
          </div>
          <div class="address">{{item.regionName}}{{item.address}}</div>
        </div>
        <div class="bottom">
          <div class="l">
            <div class="check" :class="item.defaultAddress ? 'active' : ''" @click="setDefAddress(item.id)"></div>默认地址
          </div>
          <div class="r">
            <div class="edit btn" @click="$router.push({path:'/account/edit', query:{id: item.id}})">
              <i class="icon-edit"></i>编辑
            </div>
            <div class="del-address btn" @click="delAddress(item.id)">
              <i class="icon-address-del"></i>删除
            </div>
          </div>
        </div>
      </li>
    </ul>

    <no-data v-if="nodata"></no-data>

    <div class="fixed-bottom warp">
      <router-link to="/account/edit" class="fixed-box jb-r-l">
        添加新地址
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      Lists: [],
      nodata: false,
    }
  },
  created() {
  },
  mounted() {
    window.addEventListener("pageshow", function(){
        if(sessionStorage.getItem("need-refresh")){
            location.reload();
            sessionStorage.removeItem("need-refresh");
        }
    });
    this.getUserAddress();
  },
  methods: {
    // 设置默认地址
    setDefAddress(id) {
      this.userSetDefAddress({
        id: id
      }).then((res)=> {
        this.getUserAddress();
      })
    },
    // 修改地址
    changeAddress(i) {
      if (this.$route.query.address) {
        localStorage.setItem('cgAddress', this.Lists[i].id)
        this.$router.go(-1);
      }
    },
    // 用户地址
    getUserAddress() {
      this.userAddressList({
        v:new Date().getTime(),
      }).then((res)=> {
        this.Lists = res.data;
        this.nodata = res.data.length > 0 ? false : true;
      })
    },
    // 删除地址
    delAddress(id) {
      this.$MessageBox({
        title: '温馨提示',
        message: '确定删除此地址吗?',
        showCancelButton: true
      }).then((a)=> {
        if (a == 'confirm') {
          this.userDelAddress({
            id: id,
          }).then(()=> {
            this.getUserAddress();
          });
        }
      })
    },
    ...mapActions("guide", [
      "userAddressList",
      "userDelAddress",
      "userSetDefAddress",
    ]),
  },
}
</script>
<style lang='less' scoped>
.fixed-bottom{
  height: .9rem;
}
.fixed-box{
  position: fixed;
  bottom: .5rem;
  left: 5%;
  width: 90%;
  height: .9rem;
  text-align: center;
  line-height: .9rem;
  font-size: .32rem;
  color: #fff;
  font-weight: bold;
  border-radius: .5rem;
}
.check{
  width: .38rem;
  height: .38rem;
  border-radius: 50%;
  border: .02rem solid #d6d6d6;
  margin-right: .15rem;
  &.active{
    background:url('../../../assets/images/img/check-active.png') no-repeat;
    background-size: 100%;
    border: none;
  }
}
.address-list{
  padding-top: .2rem;
  .bottom{
    height: .9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .r{
      display: flex;
      align-items: center;
      font-size: .26rem;
      color: #727272;
      .btn{
        display: flex;
        align-items: center;
      }
      .edit{
        margin-right: .58rem;
      }
      i{
        margin-right: .1rem;
      }
    }
    .l{
      display: flex;
      align-items: center;
      font-size: .26rem;
      color: #727272;
    }
  }
  .item{
    background: #fff;
    padding-top: .37rem;
    padding-left: .3rem;
    padding-right: .3rem;
    border-radius: .1rem;
    margin-bottom: .2rem;
    .top{
      font-size: .28rem;
      color: #262626;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      line-height: 1;
    }
    .address{
      font-size: .28rem;
      color: #bfbfbf;
      margin-top: .35rem;
      padding-bottom: .35rem;
      border-bottom: 1px solid #f7f7f7;
    }
  }
}
</style>